import {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ColumnChooser, DataGrid, Editing, GroupItem, SearchPanel, Summary, TotalItem} from "devextreme-react/data-grid";

import {removeEditButtonsFromToolbar} from "../../../components/utils";
import {setChangedChildren, setChangedParent, setChangedShares, updateChildren, updateEditState, updateParentEditParentState, updateParentEditSharesState, updateParentEditState} from "../editMenuSlice";
import useSalesPlanEntity, {useSalesPlanEntities} from "../../../api/useSalesPlanEntity";
import {ScrollView} from "devextreme-react";
import {useQueryClient} from "react-query";


const STRATEGY = 'manual';

const stockColumns = {
    key: 'stock',
        name: 'stock',
        caption: 'Stock',
        isBand: true,
        visible: false,
        allowHiding: true,
        showInColumnChooser: true,
        columns: [
        {
            dataField: 'stock.stock_fba',
            caption: 'FBA stock',
            key: 'fbaStock',
            width: 90,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
        {
            dataField: 'stock.in_transit_from_3pl_to_fba',
            caption: '3PL -> FBA',
            key: 'inTransitFrom3plToFba',
            width: 90,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
        {
            dataField: 'stock.in_transit_from_vendor_to_fba',
            caption: 'Vendor -> FBA',
            key: 'inTransitFromVendorToFba',
            width: 110,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
        {
            dataField: 'stock.stock_3pl',
            caption: '3PL stock',
            key: '3plStock',
            width: 90,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
        {
            dataField: 'stock.in_transit_to_3pl',
            caption: 'Transit to 3PL',
            key: 'inTransitTo3PL',
            width: 105,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
        {
            dataField: 'stock.ready_on_vendor',
            caption: 'Ready on vendor',
            key: 'readyOnVendor',
            width: 120,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
        {
            dataField: 'stock.in_production',
            caption: 'In production',
            key: 'inProduction',
            width: 110,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
            allowHiding: true,
            showInColumnChooser: true,
            visible: true,
        },
    ]
}


function getChildColumns(months, fromYearMonth) {
    return [{
        dataField: 'childId',
        caption: 'Children',
        width: 220,
        fixed: true,
        allowEditing: false,
        allowHeaderFiltering: true,
        allowSorting: true,
        cssClass: 'metric',
        showInColumnChooser: false,
    },
        stockColumns,
    {
        key: 'runRates',
        name: 'runRates',
        caption: `${fromYearMonth} Children Run Rates`,
        isBand: true,
        visible: true,
        allowHiding: true,
        showInColumnChooser: true,
        columns: [
        ...[30, 14, 7].map(ratePeriod => ({
            dataField: `runRates.${ratePeriod}`,
            caption: `${ratePeriod}d`,
            width: 80,
            fixed: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            allowSorting: true,
            cssClass: 'metric',
        }))]},
        ...months?.map(month => ({
        dataField: month,
        dataType: 'number',
        format: ',##0',
        showInColumnChooser: false,
        editorOptions: {
            format: ',##0',
            step: 100,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
        visible: month >= fromYearMonth,
        })),
    ];
}

function getParentColumns(months, fromYearMonth) {
    return [{
        dataField: 'productId',
        caption: 'Parent',
        width: 220,
        fixed: true,
        allowEditing: false,
        allowHeaderFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        cssClass: 'metric',
        },
        stockColumns,
        {
        key: 'runRates',
        name: 'runRates',
        caption: `${fromYearMonth} Parent Run Rates`,
        isBand: true,
        showInColumnChooser: true,
        visible: true,
        columns: [
            ...[30, 14, 7].map(ratePeriod => ({
                dataField: `runRates.${ratePeriod}`,
                caption: `${ratePeriod}d`,
                width: 80,
                fixed: true,
                allowEditing: false,
                allowHeaderFiltering: true,
                allowSorting: true,
                cssClass: 'metric',
            }))]
    }, ...months?.map(month => ({
        dataField: month,
        dataType: 'number',
        format: ',##0',
        editorOptions: {
            format: ',##0',
            step: 100,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
        showInColumnChooser: false,
        visible: month >= fromYearMonth,
    }))];
}

function getSharesColumns(months, fromYearMonth) {
    const monthColumns = months?.map(month => ({
        key: month + '_shares',
        dataField: month,
        dataType: 'number',
        format: {
            type: 'fixedPoint',
            precision: 4,
        },
        editorOptions: {
            format: {
                type: 'fixedPoint',
                precision: 4,
            },
            step: 100,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
        showInColumnChooser: false,
        visible: month >= fromYearMonth,
    }));
    const sharesColumns = [{
        dataField: 'childId',
        caption: 'Shares',
        width: 220,
        fixed: true,
        allowEditing: false,
        allowHeaderFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        cssClass: 'metric',
    },
        stockColumns,
    {
        key: 'runRates',
        name: 'runRates',
        caption: `${fromYearMonth} Run Rates shares`,
        isBand: true,
        showInColumnChooser: true,
        visible: true,
        columns: [
            ...[30, 14, 7].map(ratePeriod => ({
                dataField: `runRates.${ratePeriod}`,
                caption: `${ratePeriod}d`,
                width: 80,
                fixed: true,
                allowEditing: false,
                allowHeaderFiltering: true,
                allowSorting: true,
                cssClass: 'metric',
                format: {
                    type: 'fixedPoint',
                    precision: 4,
                }
            }))]
    }, ...monthColumns];
    const sharesGroupItems = monthColumns.map(month => ({
        alignByColumn: true,
        summaryType: 'sum',
        name: 'shares sum ' + month.dataField,
        column: month.dataField,
        displayFormat: '{0}',
        customizeText: (e) => {
            return e.value ? e.value.toFixed(4) : e.value;
        }
    }));
    return {
        sharesColumns,
        sharesGroupItems
    }
}

function getColumns(months, fromYearMonth) {
    return [{
        dataField: 'metric',
        caption: '',
        width: 220,
        fixed: true,
        allowEditing: false,
        allowHeaderFiltering: true,
        allowSorting: true,
        cssClass: 'metric',
    }, ...months?.toSorted().map(month => ({
        dataField: month,
        dataType: 'number',
        format: ',##0',
        editorOptions: {
            format: ',##0',
            step: 100,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
        visible: month >= fromYearMonth,
    }))];
}


export const ManualForecast = forwardRef((props, ref) => {
    const gridRef = useRef();
    const dispatch = useDispatch();

    const salesEntityId = useSelector(state => state.editMenu.salesEntityId);

    // Get SalesEntityData
    const salesEntity = useCallback(useSalesPlanEntity, [salesEntityId])(salesEntityId).data;
    const {months, startMonth, strategies: {[STRATEGY]: strategy}} = salesEntity;

    const columns = useMemo(() => getColumns(months, startMonth), [months, startMonth]);

    useImperativeHandle(ref, () => ({
        cancelEdit: () => gridRef.current.instance?.cancelEditData(),
        saveEditData: () => {
            gridRef.current.instance?.saveEditData()
        },
    }));

    const handleOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateEditState({
                strategyType: STRATEGY,
                editData: e.value,
            }))
        }
    };

    return (
        <DataGrid
            ref={e => {
                gridRef.current = e;
                ref(e);
            }}
            keyExpr={'metric'}
            align={'center'}
            height={'100%'}
            showBorders={true}
            loadPanel={{enabled: false}}
            dataSource={strategy.data}
            columns={columns}
            onToolbarPreparing={removeEditButtonsFromToolbar}
            onOptionChanged={handleOptionChanged}
        >
            <Editing
                allowUpdating={true}
                mode={'batch'}
            />
        </DataGrid>
    )
});

export const ManualParentForecast = forwardRef((props, ref) => {
    const gridRef = useRef();
    const parentGridRef = useRef();
    const sharesGridRef = useRef();
    const dispatch = useDispatch();
    const monthPattern = /^\d{4}\/\d{2}$/
    const queryClient = useQueryClient();
    // Get SalesEntityData
    const salesEntityIds = props.dataSource.map(child => child.id)

    const childrenChanges = useSelector(state => state.editMenu.editChildrenData);
    const parentChanges = useSelector(state => state.editMenu.editParentData);
    const sharesChanges = useSelector(state => state.editMenu.editSharesData);
    const forecastPeriod = useSelector(state => state.editMenu.forecastPeriod);

    useEffect(
        () => {
            async function invalidateSalesPlanEntities() {
                if (salesEntityIds && salesEntityIds.length > 0) {
                    // This is just a temporary hack to resolve the issue with the stale data.
                    await queryClient.invalidateQueries('sales_plan_entities', salesEntityIds)
                }
            }

            invalidateSalesPlanEntities()
        }, []
    )

    const entities = useSalesPlanEntities(salesEntityIds).data;

    let months, startMonth;

    if (entities && entities.length > 0) {
        ({ months, startMonth } = entities[0]);
        months.sort()
    }

    const columns = useMemo(() => months && startMonth ? getChildColumns(months, startMonth) : [], [months, startMonth]);
    const {sharesColumns, sharesGroupItems} = useMemo(() => months && startMonth ? getSharesColumns(months, startMonth) : [], [months, startMonth]);
    const parentColumns = useMemo(() => months && startMonth ? getParentColumns(months, startMonth) : [], [months, startMonth]);


    const [childrenStaticData, setChildrenStaticData] = useState({});
    const [initialChildrenData, setInitialChildrenData] = useState([]);
    const [childrenData, setChildrenData] = useState([]);
    const [parentData, setParentData] = useState({});
    const [shares, setShares] = useState([]);

    const [updatedChildrenData, setUpdatedChildrenData] = useState([]);
    const [updatedParentData, setUpdatedParentData] = useState({});
    const [updatedShares, setUpdatedShares] = useState([]);

    function isEqual(a, b) {
        if (a === b) {
            return true;
        }

        if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
            return false;
        }

        let keysA = Object.keys(a);
        let keysB = Object.keys(b);

        if (keysA.length !== keysB.length) {
            return false;
        }

        for (let key of keysA) {
            if (!keysB.includes(key) || !isEqual(a[key], b[key])) {
                return false;
            }
        }

        return true;
    }

    function subtractMonths(dateStr, months) {
        const [year, month] = dateStr.split('/').map(Number);
        let date = new Date(year, month - 1);
        date.setMonth(date.getMonth() - months);
        let newMonth = (date.getMonth() + 1).toString().padStart(2, '0');
        let newYear = date.getFullYear();

        return `${newYear}/${newMonth}`;
    }

    function calculateRunRateShares(childEntity, parent) {
        return Object.entries(childEntity.runRates).reduce((accumulator, [key, value]) => {
            accumulator[key] = value != null && parent.runRates[key] != null ? value / parent.runRates[key] : null
            return accumulator
        }, {})
    }

    function calculateStockShares(childEntity) {
        return Object.entries(childEntity.stock).reduce((accumulator, [key, value]) => {
            accumulator[key] = value
            return accumulator
        }, {})
    }


    function applyChangesToParentData(data, changes) {
        let updatedData = Object.entries(data).reduce((accumulator, [month, calculatedForecast]) => {
            let monthChanges = Object.keys(changes).includes(month) ? changes[month] : null
            accumulator[month] = monthChanges !== null ? monthChanges : calculatedForecast;
            return accumulator;
        }, {})
        if (!isEqual(updatedData, updatedParentData)) {
            setUpdatedParentData(updatedData);
        }
        return updatedData
    }

    function applyChangesToChildrenData(data, changes, staticData) {
        const changesByEntityId = changes.reduce((accumulator, entity) => {
            accumulator[entity.id] = entity.data;
            return accumulator;
        }, {})
        let updatedData = data.map(entity => {
            let entityChanges = changesByEntityId[entity.id] || {};
            return entity ? Object.assign(Object.entries(entity).reduce((accumulator, [month, value]) => {
                let monthChanges = Object.keys(entityChanges).includes(month) ? entityChanges[month] : null;
                accumulator[month] = monthChanges !== null ? monthChanges : value;
                return accumulator;
            }, {}), staticData[entity.id] || {}) : {}
        })

        if (!isEqual(updatedData, updatedChildrenData)) {
            setUpdatedChildrenData(updatedData);
        }

        return updatedData
    }

    function applyChangesToSharesData(data, changes) {
        let changesByEntityId = changes.reduce((accumulator, entity) => {
            accumulator[entity.id] = entity.data;
            return accumulator;
        }, {})
        let updatedData = data.map(entity => {
            let entityChanges = changesByEntityId[entity.id] || {};
            return entity ? Object.entries(entity).reduce((accumulator, [month, currentShare]) => {
                let changedShare = Object.keys(entityChanges).includes(month) ? entityChanges[month] : null;
                accumulator[month] = changedShare !== null ? changedShare : currentShare;
                return accumulator;
            }, {}) : {}
        })
        const sharesSum = updatedData.reduce((accumulator, entity) => {
            Object.entries(entity).forEach(
                ([month, share]) => {
                    if (monthPattern.test(month)) {
                        if (!Object.keys(accumulator).includes(month)) {
                            accumulator[month] = {
                                total: 0,
                                totalUntouched: 0,
                                entities: {}
                            }
                        }
                        accumulator[month]['total'] = accumulator[month]['total'] + share
                        if (!Object.keys(changesByEntityId).includes(String(entity.id)) || !Object.keys(changesByEntityId[String(entity.id)]).includes(month)) {
                            accumulator[month]['totalUntouched'] = accumulator[month]['totalUntouched'] + share
                            accumulator[month]['entities'][entity.id] = share
                        }
                    }
            })
            return accumulator
        }, {})

        Object.entries(sharesSum).forEach(([month, data]) => {
            if (data.total !== 1 && Object.keys(data.entities).length) {
                const remainder = 1 - data.total
                if (data.totalUntouched > 0) {
                    updatedData.forEach(entity => {
                        if (Object.keys(data.entities).includes(String(entity.id))) {
                            const share = data.entities[entity.id]
                            entity[month] = (share || 0) + (((share || 0) / data.totalUntouched) * remainder)
                        }
                    })
                }
                else {
                    const share = remainder / Object.keys(data.entities).length;
                    updatedData.forEach(entity => {
                        if (Object.keys(data.entities).includes(String(entity.id))) {
                            entity[month] = share
                        }
                    })
                }
            }
        })

        if (!isEqual(updatedShares, updatedData)) {
            setUpdatedShares(updatedData);
        }

        return updatedData
    }

    function updateChildrenData(parent, shares) {
        if (shares.length > 0 && Object.keys(parent).length > 0) {
            let calculatedData = shares.map(entity => {
                return Object.entries(entity).reduce((forecast, [key, value]) => {
                    if (typeof value !== 'number' || !monthPattern.test(key)) {
                        forecast[key] = value
                    }
                    else {
                        let parentForecast = Object.keys(parent).includes(key) ? parent[key] : 0
                        forecast[key] = parentForecast * value;
                    }
                    return forecast
                }, {})
            })
            if (!isEqual(calculatedData, childrenData)) {
                setChildrenData(calculatedData)
            }

            return calculatedData
        }
    }

    function updateParentData(children) {
        if (children.length > 0) {
            const calculatedData = children.reduce((accumulator, entity) => {
                Object.entries(entity).forEach(([month, value]) => {
                    if (value !== null && typeof value === 'number') {
                        accumulator['forecast'][month] = (accumulator['forecast'][month] || 0) + value;
                    }
                })
                Object.entries(entity.runRates).forEach(([period, value]) => {
                    if (value && typeof value === 'number') {
                        accumulator['runRates'][period] = (accumulator['runRates'][period] || 0) + value;
                    }
                })
                Object.entries(entity.stock).forEach(([stockType, value]) => {
                    if (value && typeof value === 'number') {
                        accumulator['stock'][stockType] = (accumulator['stock'][stockType] || 0) + value
                    }
                })
                return accumulator
            }, {forecast: {}, runRates: {}, stock: {}});
            months.filter(month => month >= startMonth).forEach(month => {
                if (calculatedData['forecast'][month] == null) {
                    calculatedData['forecast'][month] = 0
                }
            })
            const calculatedParentData = {
                id: 'parent',
                productId: (props.dataSource) ? props.dataSource[0].product.parent : '',
                runRates: calculatedData['runRates'],
                stock: calculatedData['stock'],
                ...calculatedData['forecast']
            }
            if (!isEqual(calculatedParentData, parentData)) {
                setParentData(calculatedParentData);
            }
            return calculatedParentData
        }
    }

    function updateSharesData(children, parent) {
        if (children.length > 0 && Object.keys(parent).length > 0) {
            const calculatedShares = children.map(entity => {
                let share = {
                    id: entity.id,
                    childId: entity.childId,
                    runRates: calculateRunRateShares(entity, parent),
                    stock: calculateStockShares(entity),
                }
                months.filter(month => month >= startMonth).forEach(month => {
                    let parentForecast = parent[month]
                    let childForecast = entity[month]
                    if (childForecast && parentForecast && (typeof parentForecast === 'number') && (typeof childForecast === 'number') && parentForecast > 0) {
                        share[month] = childForecast / parentForecast;
                    }
                    else {
                        share[month] = 0
                    }
                })
                return share
            })
            if (!isEqual(calculatedShares, shares)) {
                setShares(calculatedShares);
            }

            return calculatedShares
        }
    }

    function recalculateSharesForSalesPeriod(children, parent, period) {
        if (children.length > 0 && Object.keys(parent).length > 0) {
            const forecastPeriodStartMonth = subtractMonths(startMonth, period)
            const totalSales = Object.entries(
                parent
            ).filter(
                ([month, forecast]) => typeof forecast === 'number' && month >= forecastPeriodStartMonth && month < startMonth
            ).reduce((accumulator, [month, forecast]) => {
                return accumulator + forecast;
            }, 0)
            const calculatedShares = children.map(entity => {
                let share = {
                    id: entity.id,
                    childId: entity.childId,
                    runRates: calculateRunRateShares(entity, parent),
                    stock: calculateStockShares(entity),
                }
                const entitySales = Object.entries(entity).filter(
                    ([key, forecast]) => typeof forecast === 'number' && monthPattern.test(key) && key >= forecastPeriodStartMonth && key < startMonth
                ).reduce((accumulator, [month, forecast]) => {
                    return accumulator + forecast
                }, 0)
                const calculatedShare = entitySales / totalSales
                Object.entries(entity).forEach(([month, forecast]) => {
                    if (typeof forecast === 'number' && monthPattern.test(month)) {
                        share[month] = month >= startMonth ? calculatedShare : forecast / parent[month];
                    }
                })
                share['id'] = entity.id;
                share['childId'] = entity.childId;
                return share
            })
            if (!isEqual(calculatedShares, shares)) {
                setShares(calculatedShares);
            }

            return calculatedShares
        }
    }

    function resetData() {
        if (entities && entities.length > 0) {
            const staticData = {};
            const initialData = entities.map(entity => {
                let result = entity.strategies[STRATEGY].data[0];
                entity.predictions.forEach((prediction) => {
                    const month = `${String(prediction.year).padStart(2, '0')}/${String(prediction.month).padStart(2, '0')}`;
                    if (month < startMonth) {
                        result[month] = prediction.forecast;
                    }
                })
                result['id'] = entity.id;
                result['runRates'] = entity.run_rates?.reduce((acc, rate) => {
                    acc[rate.rate_days] = rate.forecast
                    return acc
                }, {})
                const asins = entity?.product?.asins
                if (asins && asins.length > 0) {
                    result['childId'] = entity.product.asins[0].id
                }
                else {
                    result['childId'] = entity.productId
                }

                staticData[entity.id] = {
                    runRates: result.runRates,
                    childId: result.childId,
                    stock: entity.stock && entity.stock.length > 0 ? entity.stock[0] : {}
                }
                return result;
            });
            if (!isEqual(initialData, childrenData)) {
                setChildrenData(initialData)
            }
            setInitialChildrenData(initialData);
            setChildrenStaticData(staticData);
            const updChildren = applyChangesToChildrenData(initialData, childrenChanges || [], staticData)
            const parent = updateParentData(updChildren)
            const updParent = applyChangesToParentData(parent, parentChanges || [])
            const sharesData = updateSharesData(updChildren, updParent)
            applyChangesToSharesData(sharesData, sharesChanges || [])
        }
    }

    // initial data loaded
    useEffect(() => {
        resetData()
    }, [entities]);

    useEffect(() => {
        const initialChildrenById = initialChildrenData.reduce((accumulator, child) => {
            accumulator[child.id] = child
            return accumulator
        }, {})

        const changedChildren = updatedChildrenData.reduce((accumulator, child) => {
            const changedForecast = Object.entries(child).reduce((accumulator, [month, forecast]) => {
                if (initialChildrenById[child.id][month] !== forecast) {
                    accumulator[month] = forecast
                }
                return accumulator
            }, {})
            if (Object.keys(changedForecast).length > 0) {
                accumulator.push({
                    data: changedForecast,
                    id: child.id,
                    key: 'Forecast',
                    type: 'update'
                })
            }
            return accumulator
        }, [])
        dispatch(updateChildren(changedChildren))

    }, [initialChildrenData, updatedChildrenData]);


    // parent forecast updated
    useEffect(() => {
        if (parentData && Object.keys(parentData).length > 0 && parentChanges && Object.keys(parentChanges).length > 0) {
            const updParent = applyChangesToParentData(parentData, parentChanges)
            if (!updatedShares || updatedShares.length === 0) {
                return
            }
            const children = updateChildrenData(updParent, updatedShares)
            applyChangesToChildrenData(children, childrenChanges || [], childrenStaticData)
        }
    }, [parentChanges])

    // child forecast updated
    useEffect(() => {
        if (childrenData && childrenData.length > 0 && childrenChanges && childrenChanges.length > 0) {
            const updChildren = applyChangesToChildrenData(childrenData, childrenChanges, childrenStaticData)
            const parent = updateParentData(updChildren)
            const updParent = applyChangesToParentData(parent, parentChanges || [])
            const sharesData = updateSharesData(updChildren, updParent)
            applyChangesToSharesData(sharesData, sharesChanges || [])
        }
    }, [childrenChanges])

    // shares updated
    useEffect(() => {
        if (shares && shares.length > 0 && sharesChanges && sharesChanges.length > 0) {
            const updShares = applyChangesToSharesData(shares, sharesChanges)
            if (!updatedParentData || Object.keys(updatedParentData).length === 0) {
                return
            }
            const children = updateChildrenData(updatedParentData, updShares)
            applyChangesToChildrenData(children, childrenChanges || [], childrenStaticData)
        }
    }, [sharesChanges]);

    // shares sales period changed
    useEffect(() => {
        if (forecastPeriod && updatedParentData && Object.keys(updatedParentData).length > 0 && updatedChildrenData && updatedChildrenData.length > 0) {
            const sharesData = recalculateSharesForSalesPeriod(updatedChildrenData, updatedParentData, forecastPeriod)
            const updShares = applyChangesToSharesData(sharesData, sharesChanges || [])
            const children = updateChildrenData(updatedParentData, updShares)
            applyChangesToChildrenData(children, childrenChanges || [], childrenStaticData)
        }
    }, [forecastPeriod]);

    useEffect(() => {
        const parent = Object.fromEntries(Object.entries(updatedParentData).filter(([key, value]) => monthPattern.test(key) && key >= startMonth))
        dispatch(setChangedParent(parent));
    }, [updatedParentData]);

    useEffect(() => {
        const shares = updatedShares.map(entity => {
            return Object.fromEntries(Object.entries(entity).filter(([key, value]) => monthPattern.test(key) && key >= startMonth))
        })
        dispatch(setChangedShares(shares));
    }, [updatedShares]);

    useEffect(() => {
        const children = updatedChildrenData.map(entity => {
            return Object.fromEntries(Object.entries(entity).filter(([key, value]) => monthPattern.test(key) && key >= startMonth))
        })
        dispatch(setChangedChildren(children));
    }, [updatedChildrenData]);

    useImperativeHandle(ref, () => ({
        cancelEdit: () => {
            parentGridRef.current.instance?.cancelEditData();
            sharesGridRef.current.instance?.cancelEditData();
            gridRef.current.instance?.cancelEditData();
            resetData();
        },
        saveEditData: () => {gridRef.current.instance?.saveEditData()},
    }));

    const handleOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateParentEditState(
                e.value
            ))
        }
    };

    const handleParentOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateParentEditParentState(
                e.value
            ))
        }
    };

    const handleSharesOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateParentEditSharesState(
                e.value
            ))
        }
    };

    return (
        <ScrollView>
        <div style={{overflow: 'scroll', display: 'flex', flexDirection: 'column'}}>
                <DataGrid
                    ref={e => {
                        parentGridRef.current = e;
                        ref(e);
                    }}
                    style={{overflow: 'scroll', flex: 2, marginBottom: '1rem',  width: '100%'}}
                    keyExpr={'id'}
                    align={'center'}
                    height={'100%'}
                    showBorders={true}
                    loadPanel={{enabled: false}}
                    dataSource={Object.keys(updatedParentData).length > 0 ? [updatedParentData] : []}
                    columns={parentColumns}
                    onToolbarPreparing={removeEditButtonsFromToolbar}
                    onOptionChanged={handleParentOptionChanged}
                >
                    <Editing
                        allowUpdating={true}
                        mode={'batch'}
                    />
                    <ColumnChooser enabled={true} mode={'select'} />
                </DataGrid>
                <DataGrid
                    ref={e => {
                        sharesGridRef.current = e;
                        ref(e);
                    }}
                    keyExpr={'id'}
                    align={'center'}
                    height={'100%'}
                    style={{overflow: 'scroll', flex: 3, marginBottom: '1rem',  width: '100%'}}
                    showBorders={true}
                    loadPanel={{enabled: false}}
                    dataSource={updatedShares}
                    columns={sharesColumns}
                    onToolbarPreparing={removeEditButtonsFromToolbar}
                    onOptionChanged={handleSharesOptionChanged}
                >
                    <Editing
                        allowUpdating={true}
                        mode={'batch'}
                    />
                    <SearchPanel visible={true} width={240} placeholder="Search..." />
                    <Summary>
                        {sharesGroupItems?.map(i => <GroupItem {...i}/>)}
                        {sharesGroupItems?.map(i => <TotalItem {...i}/>)}
                    </Summary>
                    <ColumnChooser enabled={true} mode={'select'} />
                </DataGrid>
                <DataGrid
                    ref={e => {
                        gridRef.current = e;
                        ref(e);
                    }}
                    style={{overflow: 'scroll', flex: 3, marginBottom: '1rem',  width: '100%'}}
                    keyExpr={'id'}
                    align={'center'}
                    height={'100%'}
                    showBorders={true}
                    loadPanel={{enabled: false}}
                    dataSource={updatedChildrenData}
                    columns={columns}
                    onToolbarPreparing={removeEditButtonsFromToolbar}
                    onOptionChanged={handleOptionChanged}
                >
                    <Editing
                        allowUpdating={true}
                        mode={'batch'}
                    />
                    <SearchPanel visible={true} width={240} placeholder="Search..." />
                    <ColumnChooser enabled={true} mode={'select'} />
                </DataGrid>
        </div>
        </ScrollView>
    )
});
